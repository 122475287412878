import type React from "react";
import type { Position } from "../doge/types/types.ts";

import { useCallback, useEffect, useRef, useState } from "react";
import map from "./assets/world.svg";
import SafeWrapper from "../../common/components/SafeWrapper/SafeWrapper.tsx";
import { Pin } from "./components/pin.tsx";

const pinsPositions: Array<Position> = [
  { x: 975, y: 125 }, // Питер
  { x: 990, y: 105 }, // Петрозаводск
  { x: 945, y: 145 }, // Калининград
  { x: 1010, y: 200 }, // Крым
  { x: 1060, y: 145 }, // Казань
  { x: 1110, y: 135 }, // Екатеринбург
  { x: 1225, y: 165 }, // Алтай
  { x: 1305, y: 155 }, // Байкал
  { x: 1445, y: 245 }, // Сеул
  { x: 1525, y: 160 }, // Камчатка
];

export const OurJourney: React.FC = () => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [currentScrollPosition, setCurrentScrollPosition] = useState(0);

  const onScrollCallback = useCallback(() => {
    if (scrollRef.current) {
      setCurrentScrollPosition(scrollRef.current.scrollLeft);
    }
  }, [scrollRef, setCurrentScrollPosition]);

  useEffect(() => {
    const copyScroll = scrollRef.current!;
    if (copyScroll) {
      copyScroll.addEventListener("scroll", onScrollCallback);
    }

    return () => {
      if (copyScroll) {
        copyScroll.removeEventListener("scroll", onScrollCallback);
      }
    };
  }, [scrollRef, onScrollCallback]);

  return (
    <div>
      <SafeWrapper>
        <div
          id={"scroller"}
          ref={scrollRef}
          style={{
            display: "flex",
            flexWrap: "nowrap",
            overflowX: "scroll",
            width: "100%",
            height: "750px",
            // background: `url(${map})`,
            backgroundRepeat: "no-repeat",
          }}
        >
          <img
            src={map}
            height={"auto"}
            alt={"Map"}
            style={{
              maxWidth: "2000px",
            }}
          />
        </div>
        {pinsPositions.map((p, i) => (
          <Pin
            key={i}
            position={{
              x: p.x - currentScrollPosition,
              y: p.y,
            }}
          />
        ))}
      </SafeWrapper>
    </div>
  );
};
